import React from 'react';
import {Link, useLocation } from 'react-router-dom';
import { useStickyHeader } from './StickyHeader.js';
import NavBar from './NavBar';


export default function Header() {

    const location = useLocation();
    const isFaqPage = location.pathname === '/faq';
    const isRSVPPage = location.pathname === '/rsvp';
    const isSticky = useStickyHeader();

    return (
        <header className={`header ${(isFaqPage || isRSVPPage) ? 'faq' : ''} ${isSticky ? 'scrolled' : ''}`}>
            <div className="header-title">
                <h3><Link to="/">home</Link></h3>
                <h3><Link to="/about">about us</Link></h3>
                <h3><Link to="/travel">travel & stay</Link></h3>
                <h3><Link to="/things">things to do</Link></h3>
                {/* <h3><Link to="/registry">Registry</Link></h3> */}
                <h3><Link to="/faq">q&a</Link></h3>
                <h3><Link to="/rsvp">rsvp</Link></h3>
            </div>
            <NavBar />
        </header>
    )
}
