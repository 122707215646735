import { useState, useEffect } from 'react';

export function useStickyHeader() {

    const [isSticky, setIsSticky] = useState(false);
    
    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 50 ? true : false);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return isSticky;
}   