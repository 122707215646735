import React, { useState } from "react";
import { useStickyHeader } from "./StickyHeader.js";
import { Link } from "react-router-dom";

export default function NavBar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isSticky = useStickyHeader();
  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const today = new Date();
  const wedding = new Date("2025-06-21");
  const diff = wedding - today;
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

  return (
    <div className={`navbar ${isSticky ? "scrolled" : ""}`}>
      <div className={`menu-toggle`} onClick={toggleMenu}>
        <div className={`line1 ${isMenuOpen ? "active" : ""}`}></div>
        <div className={`line2 ${isMenuOpen ? "active" : ""}`}></div>
        <div className={`line3 ${isMenuOpen ? "active" : ""}`}></div>
      </div>
      {isMenuOpen && (
        <div className={`menu-backdrop ${isMenuOpen ? "open" : ""}`}>
          <ul className="menu">
            <li onClick={toggleMenu}>
              <Link to="/">HOME</Link>
            </li>
            <li onClick={toggleMenu}>
              <Link to="/about">ABOUT US</Link>
            </li>
            <li onClick={toggleMenu}>
              <Link to="/travel">TRAVEL + STAY</Link>
            </li>
            <li onClick={toggleMenu}>
              <Link to="/things">THINGS TO DO</Link>
            </li>
            {/* <li onClick={toggleMenu}><Link to="/registry">REGISTRY</Link></li> */}
            <li onClick={toggleMenu}>
              <Link to="/faq">Q&A</Link>
            </li>
            <li onClick={toggleMenu}>
              <Link to="/rsvp">RSVP</Link>
            </li>
            <p>
              <em>{days} days to go!</em>
            </p>
          </ul>
        </div>
      )}
    </div>
  );
}
