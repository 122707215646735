import "./App.css";
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './Components/Header';

const HomeLazy = React.lazy(() => import('./Components/Home'))
const AboutLazy = React.lazy(() => import('./Components/About'))
const TravelLazy = React.lazy(() => import('./Components/Travel'))
const ThingsLazy = React.lazy(() => import('./Components/Things'))
const FAQLazy = React.lazy(() => import('./Components/FAQ'))
const RSVPLazy = React.lazy(() => import('./Components/RSVP'))

export default function App() {
    return (
        <div className="App">
            <Header />     
            <div className="Components">
                <Suspense fallback={<></>}>
                <Routes>
                    <Route path="/" element={<HomeLazy />} />
                    <Route path="/about" element={<AboutLazy />} />
                    <Route path="/travel" element={<TravelLazy />} />
                    <Route path="/things" element={<ThingsLazy />} />
                    <Route path="/faq" element={<FAQLazy />} />
                    <Route path="/rsvp" element={<RSVPLazy />} />
                </Routes>
                </Suspense>
            </div>
        </div>
    )
}